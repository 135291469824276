var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Accordion, AccordionDetails, AccordionSummary, Box, Card, Grid, List, Typography, } from "@mui/material";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import VerifiedIcon from "@mui/icons-material/Verified";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import GavelIcon from "@mui/icons-material/Gavel";
import SyncIcon from "@mui/icons-material/Sync";
import { ExpandMore } from "@mui/icons-material";
export var Processos = function (_a) {
    var processos = _a.processos, isAuthenticated = _a.isAuthenticated;
    return (_jsx(Box, { children: _jsx(Card, __assign({ sx: {
                py: 2,
                px: 6,
            } }, { children: _jsx(Grid, __assign({ container: true }, { children: _jsxs(Grid, __assign({ item: true, xs: 12 }, { children: [_jsx(Typography, __assign({ align: "center", variant: "h5", gutterBottom: true }, { children: "Seus Processos" })), _jsx("hr", {}), isAuthenticated ? (_jsx(Box, __assign({ fontWeight: "bold", mt: 5 }, { children: _jsx(List, __assign({ dense: true }, { children: processos.map(function (processo) { return (_jsx("div", { children: _jsxs(Accordion, { children: [_jsx(AccordionSummary, __assign({ expandIcon: _jsx(ExpandMore, {}), "aria-controls": "panel".concat(processo.id, "-content"), id: "panel".concat(processo.id, "-header") }, { children: _jsxs(Typography, __assign({ style: {
                                                        fontSize: "24px",
                                                    } }, { children: [processo.numeroProcesso, " -", " ", processo.tituloProcesso] })) })), _jsx(AccordionDetails, { children: processo.etapasProcessos.map(function (etapa) {
                                                    return (_jsx(Timeline, __assign({ position: "alternate" }, { children: _jsxs(TimelineItem, { children: [_jsxs(TimelineSeparator, { children: [_jsx(TimelineConnector, {}), _jsx(TimelineDot, { children: etapa.descricao ===
                                                                                "Abertura de Solicitação" ? (_jsx(AddCircleOutlineIcon, {})) : etapa.descricao ===
                                                                                "Documentos Verificados" ? (_jsx(VerifiedIcon, {})) : etapa.descricao === "Em Análise" ? (_jsx(HourglassBottomIcon, {})) : etapa.descricao === "Concluído" ? (_jsx(DoneAllIcon, {})) : etapa.descricao ===
                                                                                "Documentação Enviada" ? (_jsx(UploadFileIcon, {})) : etapa.descricao ===
                                                                                "Endereço Atualizado" ? (_jsx(SyncIcon, {})) : etapa.descricao ===
                                                                                "Verificação de Documentos" ? (_jsx(AssignmentTurnedInIcon, {})) : etapa.descricao ===
                                                                                "Segunda Via Emitida" ||
                                                                                etapa.descricao ===
                                                                                    "Passaporte Emitido" ||
                                                                                etapa.descricao ===
                                                                                    "Certidão Emitida" ? (_jsx(GavelIcon, {})) : etapa.descricao ===
                                                                                "Pagamento Confirmado" ||
                                                                                etapa.descricao ===
                                                                                    "Consulta de Dados" ? (_jsx(VerifiedIcon, {})) : (_jsx(DoneAllIcon, {})) }), _jsx(TimelineConnector, {})] }), _jsxs(TimelineContent, __assign({ sx: { py: "12px", px: 2 } }, { children: [_jsx(Typography, __assign({ variant: "h6", component: "span" }, { children: etapa.descricao })), _jsx(Typography, { children: etapa.criadoEm })] }))] }) })));
                                                }) })] }, processo.id) }, processo.id)); }) })) }))) : (_jsx(Typography, __assign({ variant: "subtitle1", align: "center" }, { children: "Inicie a sess\u00E3o para enviar uma notifica\u00E7\u00E3o." })))] })) })) })) }));
};
